@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Lato&family=Raleway:wght@100;200;300&display=swap');
:root {
  --light-background-container: #e4e4ec;
  --light-background-secundary: #f4f4f4;
  --light-background-primary: #fcfcff;
  --light-background-primary-transparency: #fcfcffcc;
  --light-list-title: #7f307f;
  --light-list-hover: #efff;
  --light-chevron-fill: #f5b221;
  --light-text-title: #effffa;
  --light-text-semi-dark: #000b;
  --light-border-separator: #fff9;
  --light-box-shadow: #999;
  --light-button-submit: #a535a9;
  --dark-background-container: #e4e4ec;
  --dark-background-secundary: #465672;
  --dark-background-primary: #596980;
  --dark-background-primary-transparency: #fcfcffcc;
  --dark-list-title: #465672;
  --dark-list-hover: #efff;
  --dark-chevron-fill: #FFFFFFbb;
  --dark-text-title: #FFFFFFbb;
  --dark-text-semi-dark: #FFFFFFdd;
  --dark-border-separator: #596980aa;
  --dark-box-shadow: #999;
  --dark-button-submit: #f5b221;
}
